<template>
  <section v-if="information">

    <b-overlay :show="loading" rounded="sm">
        <div class="px-3 py-2 create-message">

          <div class="pt-2">

            <b-form-group label-cols-md="4" :label="$t('NAME')">
              <b-form-input trim
                required
                type="text"
                v-model="payload.name"
                name="name"
              ></b-form-input>
            </b-form-group>

            <b-form-group label-cols-md="4" :label="$t('ADMINLEVEL.TITLE')">
              <b-form-select
                v-model="payload.is_admin"
                :options="adminOptions"
              ></b-form-select>
            </b-form-group>

            <b-form-group label-cols-md="4" :label="$t('LANGUAGE.TITLE')">
              <b-form-select name="edit_invite_select_language" v-model="payload.language">
                <b-form-select-option
                  v-for="(value, key) in sefos_locales"
                  :key="key"
                  :value="key"
                >
                  {{ $t("LANGUAGE." + key) }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>

            <b-form-group
              label-cols-md="4"
              :label="$t('AUTH_METHODS.SELECT_METHOD')"
            >
              <b-form-select
                @input="changedAuth"
                v-model="selectedIndex"
                :options="AuthTypeOptions"
              ></b-form-select>

              <div v-if="AuthMethodOptions.length != 0">
                <b-form-select
                  v-model="selectedChildIndex"
                  :options="AuthMethodOptions"
                  class="mt-2 mb-2"
                ></b-form-select>
              </div>


              <Pnr class="mt-2" 
                required
                @valid="setValidated"
                @changed="changedData"
                v-if="payload.auth_type == 'se-eid'"></Pnr>    
                
              <Pnr class="mt-2" 
                @valid="setValidated"
                v-model="payload.data.pnr"
                v-if="currentAuthType == 'se-eid'"></Pnr>    

              <Phone class="mt-2" 
                @valid="setValidated"
                required
                v-model="payload.data.identifier"
                v-if="currentAuthType == 'password-sms'"></Phone>     
              
              <Identifier class="mt-2" 
                @valid="setValidated"
                v-model="payload.data.identifier"
                :placeholder="$t('UNIQUE_IDENTIFIER')"
                v-if="showIdentifier"
              ></Identifier>              

            </b-form-group>

            <hr class="p-0 m-0 mt-2 mb-2" />

            <b-button
              :disabled="!canUpdate"
              variant="primary"
              class="btn-fill"
              @click="updateInvite"
              >{{ $t("SAVE") }}</b-button
            >
            
      <b-button
        variant="danger"
        class="btn-fill float-right"
        @click="removeSubmit"
        >{{ $t("REMOVE") }}</b-button
      >
          </div>
        </div>
      </b-overlay>
    </section>
</template>
<script>
import Pnr from "@/components/Input/Pnr.vue";
import Phone from "@/components/Input/Phone.vue";
import Identifier from "@/components/Input/Identifier.vue";
export default {
  components: {  Pnr, Identifier, Phone },
  props: ["organisation", "information","inviteId"],
  data() {
    return {
      firstRun: true,
      validated: true,
      loading: false,
      show: false,
      selectedIndex: 0,
      selectedChildIndex: 0,
      AuthMethods: [],
      AuthTypeOptions: [],
      AuthMethodOptions: [],
      methods: {},
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      options: [],
      payload: {
        id: this.inviteId,
        email: "",
        name: this.information.name,
        data: {
          identifier: this.information.data.identifier,
          password1: '',
          password2: '',
          pnr: ''
        },
        language: this.$i18n.locale,
        is_admin: this.information.is_admin,
        auth_type: "",
        auth_method: "",
        organisation_id: this.organisation.id,
      }
    };
  },
  methods: {
    removeSubmit: function() {
      let self = this;   
      this.$bvModal.msgBoxConfirm(this.$t('ARE_YOU_SURE'), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('YES'),
        cancelTitle: this.$t('NO'),
        bodyClass: 'messagebox',
        footerClass: 'p-2  text-center messagebox',
        hideHeaderClose: false,
        centered: true
      })
      .then(function(value){
        if(value)
        {      
          let invites = [];
          invites.push(self.inviteId);
          self.loading = true;
          self.$http
            .post(self.user.hostname + "/invite/remove", {
              invites: invites
            })
            .then(function() {
              self.$emit("removedInvite");
              self.$noty.info(self.$t("REMOVED"));
            })
            .catch(function() {
              self.loading = false;
            });
        }
      })
      .catch(function(){
      }); 
    },
    showSidebar(){
      this.getMethods();
    },
    changedAuth() {
      this.payload.data = {
        identifier:  this.information.data.identifier,
        password1: '',
        password2: '',
        pnr: ''
      };
      this.ProcessAuthMethods();
    },
    setValidated(value) {
      this.validated = value;
    },
    updateInvite: function() {
      let self = this;
      this.loading = true;
      this.payload.auth_type = this.currentAuthType;
      this.payload.auth_method = this.currentAuthMethod;
      this.$http
        .post(
          this.user.hostname + "/invite/update",
          this.payload
        )
        .then(function() {
          self.$emit("updatedInvite");
          self.$noty.info(self.$t("SAVED"));
        })
        .catch(function() {
          self.loading = false;
        });
    },
    async ProcessAuthTypes() {
      this.options = [];
      for (let ix = 0; ix < this.AuthMethods.length; ix++) {
        let AuthMethod = this.AuthMethods[ix];
        let add = true;
        if((AuthMethod.admin == 1) && (this.user.information.organisation.is_admin == 0))
        {
          add = false;
        } 
        let optionName = "";
        if(AuthMethod.custom_name != "")
        {
          optionName = AuthMethod.custom_name;
        }else{
          optionName = this.$t("AUTH_METHODS." + AuthMethod.auth_type);
        }
        if(add) {         
          this.AuthTypeOptions.push({
            value: ix,
            text: optionName,
          });
        }
        if(this.information.auth_method != "custom")
        {
          if(this.information.auth_type == AuthMethod.auth_type)
          {          
            this.selectedIndex = ix;
          }
        }else{
          if( (this.information.auth_type == AuthMethod.auth_type) && (this.information.auth_method == AuthMethod.auth_method) )
          {
            this.selectedIndex = ix;
          }
        }
        this.ProcessAuthMethods();
      }  
    },
    async getMethods() {
      let self = this;
      self.methods = [];
      await this.$http
        .get(this.user.hostname + "/invite/methods/list")
        .then(function(result) {
          self.AuthMethods = result.data;
          self.ProcessAuthTypes();
        })
        .catch(function() {
          self.loading = false;
        });
    },
    ProcessAuthMethods()
    { 
      if(this.currentAuthType == "password"){
        this.setValidated(true);
      }else{
        if(this.showIdentifier == false){
          this.setValidated(true);
        }
      }
      this.AuthMethodOptions = [];
      let AuthMethod = this.AuthMethods[this.selectedIndex];      
      if( (AuthMethod.auth_type == this.information.auth_type) && (AuthMethod.auth_method == this.information.auth_method) ){
        this.payload.data.identifier = this.information.data.identifier;
      }else{
        this.payload.data.identifier = "";
      }
      if(AuthMethod.childs.length != 0)
      {
        if (AuthMethod.childs.length > 1) {
          this.AuthMethodOptions.push({
            value: "",
            text: this.$t("ALL"),
          });
          this.selectedChildIndex = "";
        }else{
          this.selectedChildIndex = 0;
        }        
        for (let ax = 0; ax < AuthMethod.childs.length; ax++) {
          let AuthMethodOption = AuthMethod.childs[ax];
          if(AuthMethodOption.auth_method == this.information.auth_method)
          {
            this.selectedChildIndex = ax;
          }
          this.AuthMethodOptions.push({
            value: ax,
            text: this.$t("AUTH_METHODS." + AuthMethodOption.auth_method),
          });
        }
      }
    }
  },
  computed: {
    currentAuthType(){
      let AuthMethod = this.AuthMethods[this.selectedIndex];
      if(AuthMethod != undefined){
        return AuthMethod.auth_type;
      }
      return "";
    },
    currentAuthMethod(){
      let AuthMethod = this.AuthMethods[this.selectedIndex];
      if(AuthMethod != undefined){
        if(this.currentAuthType == "custom"){
          return AuthMethod.auth_method;
        }else{       
          let AuthMethodChild = AuthMethod.childs[this.selectedChildIndex];
          if(AuthMethodChild != undefined){
            return AuthMethodChild.auth_method;
          }
        }
        return "";
      }else{
        return "";
      }
    },
    adminOptions: function() {
      if (this.user.information.is_admin == 2) {
        return [
          { value: 0, text: this.$t("ADMINLEVEL.0") },
          { value: 1, text: this.$t("ADMINLEVEL.1") },
          { value: 2, text: this.$t("ADMINLEVEL.2") },
        ];
      } else {
        return [
          { value: 0, text: this.$t("ADMINLEVEL.0") },
          { value: 1, text: this.$t("ADMINLEVEL.1") },
        ];
      }
    },
    canUpdate: function() {
      if(this.validated == false)
      {
        return false;
      }
      if(this.currentAuthType == "password-sms"){
        if(this.payload.data.identifier == ""){ return false; }
      }
      if(this.currentAuthType == "se-eid"){
        if(this.payload.data.pnr == ""){ return false; }
      }
      if(this.payload.name == ""){ return false; }
      return true;
    },
    showIdentifier() {
      if(this.currentAuthType == "custom")
      {
        return this.AuthMethods[this.selectedIndex].needs_identifier == 1;
      }
      return this.currentAuthType == "freja-org";
    }
  },
  mounted() {
    this.getMethods();
  },
};
</script>
<style></style>
