<template>
  <section v-if="inviteId != 0">
    <div class="mt-2" v-if="information">
      <h3>
        <b-img
          class="profileImage"
          src="/img/default-avatar.png"
          :alt="(information.email + ' ' + information.name)"
          rounded="circle"
        ></b-img>
        {{ information.email }}, {{ information.name }}
      </h3>
      <b-tabs>
        
        <b-tab lazy>
          <template v-slot:title>{{
              $t("INFORMATION")
            }}
          </template>  
          <InviteUpdate 
            @updatedInvite="updatedInvite"
            @removedInvite="removedInvite"
            :inviteId="inviteId"
            :information="information"
            :organisation="organisation"></InviteUpdate>
        </b-tab>



      </b-tabs>
    
    </div>
  </section>
</template>
<script>
import InviteUpdate from '@/components/Invite/Update';
export default {
  props: ["inviteId","organisation"],
  components: {
    InviteUpdate
  },
  data() {
    return {
      information: null
    };
  },
  methods: {
    updatedInvite: function() {
      this.$emit("updatedInvite");
    },
    removedInvite: function() {
      this.$emit("removedInvite")
    },
    getInformation: function() {
      let self = this;
      this.$http
        .get(this.user.hostname + "/invite/byid/" + this.inviteId)
        .then(response => {
          self.information = response.data;
        })
        .catch(() => {
        });
    }
  },
  watch: { 
    user_uuid: function(newVal, oldVal) { // watch it
      if(newVal != oldVal)
      {
        this.getInformation();
      }      
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    this.getInformation();
  }
};
</script>
<style></style>