var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.inviteId != 0
    ? _c("section", [
        _vm.information
          ? _c(
              "div",
              { staticClass: "mt-2" },
              [
                _c(
                  "h3",
                  [
                    _c("b-img", {
                      staticClass: "profileImage",
                      attrs: {
                        src: "/img/default-avatar.png",
                        alt: _vm.information.email + " " + _vm.information.name,
                        rounded: "circle",
                      },
                    }),
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.information.email) +
                        ", " +
                        _vm._s(_vm.information.name) +
                        "\n    "
                    ),
                  ],
                  1
                ),
                _c(
                  "b-tabs",
                  [
                    _c(
                      "b-tab",
                      {
                        attrs: { lazy: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _vm._v(
                                    _vm._s(_vm.$t("INFORMATION")) + "\n        "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1071631124
                        ),
                      },
                      [
                        _c("InviteUpdate", {
                          attrs: {
                            inviteId: _vm.inviteId,
                            information: _vm.information,
                            organisation: _vm.organisation,
                          },
                          on: {
                            updatedInvite: _vm.updatedInvite,
                            removedInvite: _vm.removedInvite,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }